import {onDomReady} from "../../components/dynamic/observer";
import {EffectCreative, EffectFade, Navigation, Pagination, Swiper} from "swiper";

onDomReady(() => {
  const containerElement = document.querySelector('[data-advantages-accordion-container]');
  if (containerElement) {
    const accordionItems = containerElement.querySelectorAll('[data-accordion-element]');
    const imagesElement = document.querySelector('[data-advantages-accordion-slider]');

    const imagesSlider = new Swiper(imagesElement, {
      modules: [EffectCreative],
      preventInteractionOnTransition: true,
      speed: 700,
      rewind: true,
      effect: 'creative',
      allowTouchMove: false,
      creativeEffect: {
        prev: {
          translate: [0, 0, -1],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      },
    });

    accordionItems.forEach((accordionItem) => {
      accordionItem.addEventListener('ElementOpen', () => {
        const id = accordionItem.dataset.accordionElement;
        const currentSlide = Array.from(imagesSlider.slides).find((slide) => {
          return parseInt(slide.dataset.accordionSlide, 10) === parseInt(id, 10);
        })
        imagesSlider.slideTo(imagesSlider.slides.indexOf(currentSlide));
      })
    });
  }
})