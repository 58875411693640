import polylabel from './polylabel';

/**
 * Объект на сцене (может содержать балун + плейсмарк + полигон)
 */
export class SceneObject {
  constructor(objectElement, sceneModalsElement, settings) {
    this.settings = settings;
    // Создать два (если необходимо) svgPathElement
    this.objectElement = objectElement;
    this.sceneModalsElement = sceneModalsElement;
    this.polygonElement = objectElement.querySelector('[data-gen-polygon]');
    this.placemarkElement = objectElement.querySelector('[data-gen-placemark]');
    this.balloonElement = objectElement.querySelector('[data-gen-balloon]');
    this.containerElement = document.createElement('div');
    this.type = objectElement.dataset.type ? objectElement.dataset.type : null;
    this.svgPathSubstrateElement = undefined;
    this.svgPathPolygonElement = undefined;
    this.balloonMode = 'popover'; // OR modal
    this.onBalloonCloseCallback = undefined;
    this.initSvgPaths();
    this.initContainer();
    this.bindEvents();
  }

  initSvgPaths() {
    if (!this.polygonElement) {
      return;
    }
    const substrateNeeded = this.polygonElement.dataset.layer === 'substrate';
    const rawCoordinates = this.polygonElement.dataset.coordinates;
    const color = this.polygonElement.dataset.color ? this.polygonElement.dataset.color : 'blue';
    const coordinates = JSON.parse(rawCoordinates);
    if (coordinates) {
      this.svgPathPolygonElement = this.makePathElementByCoordinates(coordinates, substrateNeeded ? 'transparent' : color);
      if (this.polygonElement.tagName === 'A') {
        this.svgPathPolygonElement.setAttributeNS(null, 'class', '_clickable');
      }
      if (this.polygonElement.dataset.sold === 'true') {
        this.svgPathPolygonElement.setAttributeNS(null, 'class', '_sold');
      }
    } else {
      console.error('Could not parse coordinates of polygonElement', this.polygonElement);
      return;
    }
    if (substrateNeeded) {
      this.svgPathSubstrateElement = this.makePathElementByCoordinates(coordinates, color);
    }
  }

  initContainer() {
    // Инициализируем контейнер с плейсмарком и балуном
    this.containerElement.classList.add('genplan__object-container');

    if (this.placemarkElement) {
      this.placemarkElement.classList.add('genplan__object-placemark');
      this.containerElement.appendChild(this.placemarkElement);
    }
    if (this.balloonElement) {
      this.balloonElement.classList.add('genplan__object-balloon');
      this.containerElement.appendChild(this.balloonElement);
    }

    // Позиционируем
    const center = this.getContainerPosition();
    if (center && center.length === 2) {
      const [x, y] = center;
      this.containerElement.style['left'] = `${x}%`;
      this.containerElement.style['top'] = `${y}%`;
    }
  }

  bindEvents() {
    if (this.svgPathPolygonElement) {
      this.svgPathPolygonElement.addEventListener('mouseover', () => {
        this.onPolygonHover();
      });

      this.svgPathPolygonElement.addEventListener('mouseout', () => {
        this.onPolygonHoverOut();
      });

      this.svgPathPolygonElement.addEventListener('click', () => {
        this.onPolygonClick();
      });
    }

    if (this.placemarkElement) {
      this.placemarkElement.addEventListener('mouseover', () => {
        this.onPlacemarkHover();
      });
      this.placemarkElement.addEventListener('mouseout', () => {
        this.onPlacemarkHoverOut();
      });
      this.placemarkElement.addEventListener('click', () => {
        this.onPlacemarkClick();
      });
    }

    if (this.balloonElement) {
      this.balloonElement.addEventListener('mouseover', () => {
        this.onBalloonHover();
      });
      this.balloonElement.addEventListener('mouseout', () => {
        this.onBalloonHoverOut();
      });
      this.balloonElement.addEventListener('click', () => {
        this.onBalloonClick();
      });

      const balloonCloser = this.balloonElement.querySelector('[data-gen-balloon-close]');
      if (balloonCloser) {
        balloonCloser.addEventListener('click', () => {
          this.onBalloonHide();
        });
      }
    }
  }

  isModalMode() {
    return this.balloonMode === 'modal';
  }

  isPopoverMode() {
    return this.balloonMode === 'modal';
  }

  getHoverApplySvgElement() {
    return this.svgPathSubstrateElement ? this.svgPathSubstrateElement : this.svgPathPolygonElement;
  }

  onPolygonHover() {
    if (this.isModalMode()) {
      return;
    }
    this.hover();
  }

  onPolygonHoverOut() {
    if (this.isModalMode()) {
      return;
    }
    this.unhover();
  }

  onPlacemarkHover() {
    if (this.isModalMode()) {
      return;
    }
    this.hover();
  }

  onPlacemarkHoverOut() {
    if (this.isModalMode()) {
      return;
    }
    this.unhover();
  }

  onBalloonHover() {
    if (this.isModalMode()) {
      return;
    }
    this.hover();
  }

  onBalloonHoverOut() {
    if (this.isModalMode()) {
      return;
    }
    this.unhover();
  }

  onPlacemarkClick() {
    if (this.isModalMode() && this.placemarkElement.dataset['onMobileClick'] === 'showBalloon') {
      this.hover();
      return;
    }

    this.handleClickOnElement(this.placemarkElement);
  }

  onPolygonClick() {
    if (this.isModalMode() && this.polygonElement.dataset['onMobileClick'] === 'showBalloon') {
      this.hover();
      return;
    }

    this.handleClickOnElement(this.polygonElement);
  }

  onBalloonClick() {

  }

  onBalloonHide() {
    this.unhover();
    if (this.onBalloonCloseCallback) {
      this.onBalloonCloseCallback();
    }
  }

  hover() {
    this.showBalloon();
    const hoverElement = this.getHoverApplySvgElement();
    this.containerElement.classList.add('_hover');

    if (hoverElement) {
      hoverElement.classList.add('_hover');
    }
  }

  unhover() {
    this.hideBalloon();
    const hoverElement = this.getHoverApplySvgElement();
    this.containerElement.classList.remove('_hover');
    if (hoverElement) {
      hoverElement.classList.remove('_hover');
    }
  }

  handleClickOnElement(element) {
    // Если элемент - это ссылка и она имеет путь, отличный от javascript:void(0), то есть какой-то вменяемый
    // то осуществляем переход как будто это обычная ссылка
    if (element.tagName === 'A' && !element.getAttribute('href').includes('javascript:void(0)')) {
      if (element.getAttribute('target') === '_blank') {
        window.open(element.getAttribute('href'), '_blank');
      } else {
        element.click();
      }
      return;
    }
    // В противном случае вызываем клик на этом элементе - скорее всего там висит обработчик
    element.click();
  }

  showBalloon() {
    if (this.balloonElement) {
      this.balloonElement.classList.add('_hover');
      this.balloonElement.classList.add('_opened');
      if (this.isModalMode()) {
        this.sceneModalsElement.classList.add('_opened');
      }
    }
  }

  hideBalloon() {
    if (this.balloonElement) {
      this.balloonElement.classList.remove('_hover');
      this.balloonElement.classList.remove('_opened');
      this.sceneModalsElement.classList.remove('_opened');
    }
  }

  makePathElementByCoordinates(coordinates, color) {
    const rawCoords = [];
    coordinates.forEach((coordinate, i) => {
      const [x, y] = coordinate
      rawCoords.push(`${x} ${y}`)
    });
    const d = 'M ' + rawCoords.join(' L ') + ' Z';
    return this.getSvgNode('path', {
      d: d,
      fill: color
    });
  }

  getPlacemarkCoordinates() {
    if (this.placemarkElement && this.placemarkElement.dataset.coordinates) {
      const rawCoords = this.placemarkElement.dataset.coordinates;
      const coords = JSON.parse(rawCoords);
      if (!coords || coords.length === 0) {
        return;
      }
      if (coords && coords.length === 2) {
        return coords;
      } else {
        console.log('Could not parse coordinates of placemarkCoordinates', this.placemarkElement);
      }
    }
    return null;
  }

  getPolygonCoordinates() {
    if (this.polygonElement) {
      const rawCoordinates = this.polygonElement.dataset.coordinates;
      const coordinates = JSON.parse(rawCoordinates);
      if (coordinates) {
        return coordinates;
      } else {
        console.error('Could not parse coordinates of polygonElement', this.polygonElement);
      }
    }
    return null;
  }

  getContainerPosition() {
    // Если у плейсмарка есть координаты - точка известна
    const placemarkCoordinates = this.getPlacemarkCoordinates();
    if (placemarkCoordinates) {
      return placemarkCoordinates;
    }

    // Если у полигона есть координаты - вычисляем центр
    const polygonCoordinates = this.getPolygonCoordinates();
    if (polygonCoordinates) {
      return this.getCenterOfPolygon(polygonCoordinates);
    }

    if (polygonCoordinates === null) {
      return;
    }

    console.error('Could not detect coordinates of object element', this.objectElement);
  }

  getCenterOfPolygon(coordinates) {
    return this.getCenterOfPolygonByPolylabel(coordinates);
  }

  getCenterOfPolygonByPolylabel(coordinates) {
    return polylabel([coordinates], 1.0, false);
  }

  getCenterOfPolygonByBorders(coordinates) {
    let top = undefined;
    let bottom = undefined;
    let left = undefined;
    let right = undefined;

    coordinates.forEach((coordinate) => {
      if (top === undefined || top < coordinate[1]) {
        top = coordinate[1];
      }
      if (bottom === undefined || bottom > coordinate[1]) {
        bottom = coordinate[1];
      }
      if (left === undefined || left < coordinate[0]) {
        left = coordinate[0];
      }
      if (right === undefined || right > coordinate[0]) {
        right = coordinate[0];
      }
    })

    // Пока тупо центр
    return [
      (left + right) / 2,
      (top + bottom) / 2
    ];
  }

  getContainerPositionPx(renderedFrame) {
    const centerOfObjectPercent = this.getContainerPosition();

    if (centerOfObjectPercent === undefined) {
      return;
    }

    return [
      renderedFrame.imageWidth * (centerOfObjectPercent[0] / 100),
      renderedFrame.imageHeight * (centerOfObjectPercent[1] / 100),
    ];
  }

  getSvgNode(nodeName, attributes) {
    const node = document.createElementNS("http://www.w3.org/2000/svg", nodeName);
    try {
      // console.log(this.objectElement);
      for (const attributeName in attributes) {
        if (attributes[attributeName].match(/\d+/)) {
          node.setAttributeNS(null, attributeName, attributes[attributeName]);
        }
      }
    } catch (e) {
      console.log('Got error on build svg node', [
        this.objectElement,
        this.containerElement,
      ]);
    }
    return node;
  }

  getSvgPathSubstrateElement() {
    return this.svgPathSubstrateElement;
  }

  getSvgPathPolygonElement() {
    return this.svgPathPolygonElement;
  }

  getContainerElement() {
    return this.containerElement;
  }

  getBorders() {
    let coordinates = this.getPolygonCoordinates();
    if (!coordinates) {
      coordinates = [];
    }
    let placemarkCoordinates = this.getPlacemarkCoordinates();
    if (placemarkCoordinates) {
      coordinates.push(placemarkCoordinates);
    }
    let top = undefined;
    let left = undefined;
    let right = undefined;
    let bottom = undefined;

    if (coordinates.length === 0) {
      return null;
    }

    coordinates.forEach((coordinate) => {
      const [x, y] = coordinate;
      top = top === undefined ? y : Math.min(top, y);
      bottom = bottom === undefined ? y : Math.max(bottom, y);
      left = left === undefined ? x : Math.min(left, x);
      right = right === undefined ? x : Math.max(right, x);
    });

    return {
      top,
      left,
      right,
      bottom,
    };
  }

  getType() {
    return this.type;
  }

  renderBalloonAsPopover(renderedFrame) {
    if (!this.balloonElement) {
      return;
    }
    this.setBalloonAsPopover();

    const centerOfVisibleContainer = [
      (renderedFrame.left + renderedFrame.right) / 2,
      (renderedFrame.top + renderedFrame.bottom) / 2
    ];

    /*
    const debugElement = document.createElement('div');
    debugElement.style.left = centerOfVisibleContainer[0] + 'px';
    debugElement.style.top = centerOfVisibleContainer[1] + 'px';
    debugElement.style.width = '2px';
    debugElement.style.height = '2px';
    debugElement.style.backgroundColor = 'red';
    debugElement.style.position = 'absolute';
    this.getContainerElement().parentElement.appendChild(debugElement);
     */

    // Вычисляем куда будет вылезать высплывашка
    const centerOfObject = this.getContainerPositionPx(renderedFrame);
    if (centerOfObject === undefined) {
      return;
    }
    // Вылезает влево/вправо относительно плейсмарка
    const xDirection = centerOfObject[0] > centerOfVisibleContainer[0] ? 'to-left' : 'to-right';
    // Вылезает вверх/вниз относительно плейсмарка
    const yDirection = centerOfObject[1] < centerOfVisibleContainer[1] ? 'to-bottom' : 'to-top';

    const balloonRect = this.balloonElement.getBoundingClientRect();
    const placemarkRect = this.placemarkElement ? this.placemarkElement.getBoundingClientRect() : null;

    // Определяем "точку привязки" балуна - то место, откуда будет отображаться "ножка"
    // Это смещение относительно центра объекта
    let anchorOffset = {};
    const xOffsetFrom = xDirection === 'to-left' ? 'right' : 'left';
    const yOffsetFrom = yDirection === 'to-top' ? 'bottom' : 'top';
    anchorOffset[xOffsetFrom] = 0;
    anchorOffset[yOffsetFrom] = 0;

    if (placemarkRect) {
      anchorOffset[xOffsetFrom] = placemarkRect.width / 2;
    }
    if (placemarkRect) {
      anchorOffset[yOffsetFrom] = -(placemarkRect.height / 2);
    }

    // Теперь нужно проверить что мы не вылезли за границы видимой области и пользователь увидит балун целиком
    // Проверяем только верх / низ - остальное маловероятно, пока не будем упарываться

    // Вычисляем границы балуна с учетом рассчитанного смещения
    let balloonTopBorder = 0;
    let balloonBottomBorder = 0;
    if (anchorOffset.top !== undefined) {
      balloonTopBorder = centerOfObject[1] + anchorOffset.top;
      balloonBottomBorder = balloonTopBorder + balloonRect.height;
    } else {
      balloonBottomBorder = centerOfObject[1] - anchorOffset.bottom;
      balloonTopBorder = balloonBottomBorder - balloonRect.height;
    }

    // Проверяем, не вылетает ли балун за границы видимой области (с учетом отступа до границы)
    // Если вылетаем - вычисляем на сколько его надо сдвинуть по оси y
    // Отрицательное значение - сдвиг вверх, положительное - вниз
    let yOffset = 0;
    if (balloonTopBorder < (renderedFrame.top + this.settings.balloonGap)) {
      yOffset = (renderedFrame.top + this.settings.balloonGap) - balloonTopBorder;
    } else if (balloonBottomBorder > (renderedFrame.bottom - this.settings.balloonGap)) {
      yOffset = (renderedFrame.bottom - this.settings.balloonGap) - balloonBottomBorder;
    }

    // Если необходимо смещение - смещаем
    if (yOffset) {
      if (anchorOffset.top !== undefined) {
        anchorOffset.top += yOffset;
      } else {
        anchorOffset.bottom -= yOffset;
      }
    }

    const pinYOffset = Math.abs(yOffset);
    const placemarkHeight = placemarkRect ? placemarkRect.height : 0;

    this.balloonElement.style.setProperty('--pin-y-offset', this.toStylePx(pinYOffset));
    this.balloonElement.style.setProperty('--placemark-height', this.toStylePx(placemarkHeight));

    this.balloonElement.style.left = this.toStylePx(anchorOffset.left);
    this.balloonElement.style.right = this.toStylePx(anchorOffset.right);
    this.balloonElement.style.top = this.toStylePx(anchorOffset.top);
    this.balloonElement.style.bottom = this.toStylePx(anchorOffset.bottom);
    this.balloonElement.classList.remove(...['to-left', 'to-right', 'to-top', 'to-bottom']);
    this.balloonElement.classList.add(xDirection, yDirection);
  }

  toStylePx(value) {
    return value !== undefined ? value + 'px' : ''
  }

  renderBalloonAsModal(renderedFrame) {
    if (!this.balloonElement) {
      return;
    }
    this.setBalloonAsModal();
  }

  setBalloonAsPopover() {
    if (!this.balloonElement) {
      return;
    }
    this.containerElement.appendChild(this.balloonElement);
    this.balloonElement.classList.remove('_modal');
    this.balloonElement.classList.add('_popover');
    this.balloonMode = 'popover';
  }

  setBalloonAsModal() {
    if (!this.balloonElement) {
      return;
    }
    this.sceneModalsElement.appendChild(this.balloonElement);
    this.balloonElement.classList.remove('_popover');
    this.balloonElement.classList.add('_modal');
    this.balloonMode = 'modal';
  }
}